.mainwrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: calc(100vh - 50px);
    overflow-x: hidden;
    overflow: auto;
    background: rgba(145, 145, 145, 0.1);
}

.media {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
	background: transparent;
	box-shadow: none;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

/*SOCIAL SHARE*/

.MuiSpeedDial-root > button,
.MuiSpeedDial-root > button:hover {
    background: rgb(0, 82, 175);
    color: #fff;
}

#SpeedDial-actions svg {
    color: #fff !important;
}

.Facebook {
    background: #3b5a9b !important;
}

.Twitter {
    background: #2baae1 !important;
}

.Instagram {
    background: #e1306c !important;
}

/*SOCIAL ICONS*/
#socialWrapper>.social {
    text-align: center;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    margin: 0 5px;
    border: 1px solid #cecece;
    cursor: pointer;
    background: transparent;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
}

.social#instagram>#instagramIcon {
    z-index: 1;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 6px);
}

.social#instagram>#instagramBackground {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
}

.social:hover {
    color: #fff;
}

.social#fivehundredpx:hover {
    background: #000;
}

.social#instagram:hover>#instagramBackground {
    opacity: 1;
}

.social#facebook:hover {
    background: #3b5a9b;
}

.social#twitter:hover {
    background: #2baae1;
}

.social#linkedin:hover {
    background: #0072b2;
}

.social#tumblr:hover {
    background: #34526f;
}

.social#youtube:hover {
    background: #c4302b;
}

.social#github:hover {
    background: #000;
}

@media only screen and (max-width: 960px) {
    /* Tablet Styles */
    #splash_bg {
        background-size: 150% !important;
        background-position: 50% 30% !important;
    }
}

@media only screen and (max-width: 420px) {
    /* Mobile Styles */
    #socialWrapper {
        width: 100%;
    }
}