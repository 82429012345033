#PhotosWrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: calc(100vw - 7px);
    min-height: calc(100vh - 310px);
    margin: 4px;
}

.photoWrapper {
    position: relative;
    flex-grow: 1;
    height: 250px;
    margin: 2px;
    overflow: hidden;
}

.photoThumbnail {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0px;
    background: rgba(0, 0, 0, 0.3);
    transition: height 0.2s;
    color: #fff;
    line-height: 45px;
    padding: 0 10px;
}

.photoWrapper:hover>.photoThumbnail {
    height: 45px;
}

.MuiDialogContent-root {
    padding: 0px !important;
    overflow-y: hidden !important;
    width: 100%;
}

.MuiDialog-paperWidthSm {
    max-width: 90vw !important;
    height: 80vh !important;
}

.photoLoading {
    height: 250px;
    width: 400px;
    display: flex;
    justify-content: center;
}

.photoLoading > svg {
	max-width: 5em;
	border-radius: 3px;
	fill: none;
	stroke: #222;
	stroke-linecap: round;
	stroke-width: 8%
}

.photoLoading > svg > use {
	stroke: #fff;
	animation: a 2s linear infinite
}

.popupimage {
    object-fit: contain;
    max-width: 90vw;
    max-height: 80vh;
}

@keyframes a { to { stroke-dashoffset: 0px } }

@media only screen and (max-width: 545px) {
    /* Mobile Styles */
    .MuiDialog-paperWidthSm {
        width: 90vw !important;
        height: auto !important;
    }
    .photoWrapper {
        height: auto;
        width: 100%;
    }
    .photoLoading {
        width: 100%;
    }
}