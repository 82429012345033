#splash {
  padding: 0;
  margin: 0;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#splash > #splash_bg {
  width: 100%;
  height: 100%;
  max-height: 100%;
}