#wrapper {
  position: relative;
  text-align: center;
  margin: auto;
  width: 100vw;
  height: calc(100vh - 240px);
}

#textLine {
  position: absolute;
  top: 35%;
  transform: translate(0, -50%);
  text-align: center;
  width: 100vw;
  font-size: 50px;
  color: #333333;
}

.lineUp {
  animation: 2s anim-lineUp ease-out;
  animation-fill-mode: none;
}

@media only screen and (min-width: 630px) and (max-width: 830px) {
  #wrapper {
    width: 100%;
    margin: 30px auto;
  }
}

@media only screen and (max-width: 630px) {
  #name {
    display: none;
  }
  h3 {
    font-size: 40px;
  }

  #wrapper {
    width: 100%;
    height: auto;
    min-height: auto;
    margin: 10px auto;
    height: calc(100vh - 250px);
  }
}

/* TEXT REVEAL ANIMATION */
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* EXTRAORDINARY HOVER ANIMATION */
@keyframes wave {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.extraordinary {
  white-space: nowrap;
}

.extraordinary span {
  display: inline-block;
  transform-origin: bottom;
}

.extraordinary:hover span {
  animation: wave 0.6s ease forwards;
  animation-fill-mode: none;
  animation-delay: calc(var(--i) * 0.05s);
}