#contactmeForm {
    margin: 40px auto;
    width: 500px;
    min-height: 600px;
    height: calc(100vh - 313px);
    border: 1px solid #2e2e2e;
    box-shadow: 10px 10px 10px rgba(0,0,0,0.1);
}

#contactmeHeader {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #cecece;
    background: #2e2e2e;
}

#contactinputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 20px;
    height: calc(100% - 50px);
}

@media only screen and (max-width: 540px) {
    #contactmeForm {
        width: calc(100% - 2px);
        margin: 10px 0;
    }
}