#codeWrapper {
	position: relative;
	width: calc(100vw - 20px);
	min-height: calc(100vh - 250px);
	margin: 10px;
}

#codeWrapper > div {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.card {
	width: 500px;
	margin: 10px;
}

.cardmedia {
	height: 300px;
}