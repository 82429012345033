#videoWrapper {
    width: 80%;
    margin: auto;
    text-align: center;
    min-height: calc(100vh - 310px);
}
#videoWrapper > iframe {
    margin: 10px;
    width: 645px;
    height: 365px;
}

@media only screen and (max-width: 650px) {
    /* Mobile Styles */
    #videoWrapper > iframe {
        width: calc(100% - 20px);
        height: auto;
    }
}