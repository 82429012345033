#menu {
    display: flex;
    height: 50px;
    line-height: 50px;
    width: calc(100vw - 10px);
    background: #fff;
    padding: 0 5px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.10196);
}

#mobileMenuToggler {
	position: absolute;
	display: none;
	width: calc(100% - 20px);
}

#mobileMenuToggler h1 {
    margin: 0;
}

#mobileMenuToggler button {
    position: absolute;
    right: 10px;
	padding: 8px;
}

.menu_item {
    position: relative;
    font-size: 14px;
    padding: 0 10px;
    margin: 0 10px;
    height: 50px;
    cursor: pointer;
}

.menu_item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0px;
    height: 2px;
    background: #333333;
    transition: all 0.5s;
}

.active.menu_item::after, .menu_item:hover::after {
    width: 100%;
    left: 0;
}

@media only screen and (max-width: 650px) {
    /* Mobile Styles */
    #menu {
        justify-content: space-around;
    }
    .menu_item {
        display: none;
        padding: 0;
        margin: 0;
    }
    #mobileMenuToggler {
        display: flex;
    }
    #menuDrawer {
        height: 100vh;
        width: 50vw;
        min-width: 210px;
        display: flex;
        flex-direction: column;
    }
    #menuDrawer h1 {
        margin: 15px;
    }
}