#footerWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100vw;
    height: 180px;
    background: #2e2e2e;
    color: #cecece;
    text-align: center;
}

#footer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100vw;
    height: 220px;
    background: #2e2e2e;
    color: #cecece;
    text-align: center;
}

#footerInfo {
    display: inline-flex;
    margin: 0 auto;
    line-height: 65px;
    text-align: center;
}

#footerAvatar {
    width: 65px;
    height: 65px;
    border: 2px solid #cecece;
}

#footerName {
    font-size: 30px;
    margin-left: 10px;
}

#footerEmail {
    font-size: 12px;
}

#copyights {
    background: #252525;
    width: 100%;
    padding: 15px 0;
    font-size: 11px;
}

#reactlink, #reactlink:focus, #reactlink:active, #reactlink:link, #reactlink:visited {
    color: #cecece;
    text-decoration: none;
}

#reactlink:hover {
    text-decoration: underline;
}